import React, { Component } from 'react';
import { Route, Switch, Router } from 'react-router-dom';
import { history } from './helpers';
// import { alertActions, fetchCustomerCount } from './actions';
import { connect } from 'react-redux';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import axios from 'axios';

import './scss/index.scss';
const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;
// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
// Pages
const Login = React.lazy(() => import('./views/Login'));
const SurveyAnswer = React.lazy(() => import('./views/Chatlive/SurveyAnswer/SurveyAnswer'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
// const Page500 = React.lazy(() => import('./views/Pages/Page500'));

class App extends Component {

  constructor(props) {
    super(props);
    axios.interceptors.response.use((response) => {
      return response
    }, err => {
      sessionStorage.removeItem("customer")
      
      if (!err.response) {
        history.push("/login")
      }

      if (err.response && err.response.status === 401) {
        history.push("/500")
      }

      return Promise.reject(err)
    })
  }
  // adding comment for testing codepipeline issue with tcs & aws team
  render() {
    return (
      <Router history={history}>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
            <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
            <Route exact path="/chatlive/survey-answer" name="survey-answer" render={props => <SurveyAnswer {...props} />} />

            <Route path="/" name="Home" render={props => <DefaultLayout {...props} />} />
            {/* <PrivateRoute exact path="/" component={HomePage} /> */}
          </Switch>
        </React.Suspense>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  return {
    alert
  };
}


export default connect(mapStateToProps)(App)
