export default {
  API_URL: process.env.REACT_APP_API_URL,
  S3_URL: process.env.REACT_APP_S3_URL,
  PORT: process.env.PORT,
  QUESTION_NAME: process.env.REACT_APP_QUESTION_NAME,
  BOT_API_URL: process.env.REACT_APP_API_ENDPOINT,
  APP_URL: process.env.REACT_APP_URL,
  LIFFSERVEY: process.env.REACT_APP_LIFF_SERVEY,
  //TODO
  CHALLENGE_API_URL: 'https://t92qrdxvx4.execute-api.ap-southeast-1.amazonaws.com/v2/external',
  CHALLENGE_X_API_KEY: '29e44a53-68ae-4fcd-8aa6-527c771dcf08'
};
